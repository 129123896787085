import {request} from "@/core/services/Request";
import Error from "@/core/services/Error";

const DEFAULT_FORM_STATE = {
    name: null,
    permissions: [],
    _method: 'post',
};

const COLUMN_DEFINATION = () => [
    {
        text: "#",
        sortable: false,
        value: 'id',
    },
    {
        text: "Name",
        sortable: false,
        value: 'name',
    },
    {
        text: "Permission",
        sortable: false,
        value: 'permissions',
    },
    {
        text: "Action",
        value: "action",
        sortable: false,
    },

];

export default {
    data() {
        return {
            operationTitle: 'Roles',
            formFields: {...DEFAULT_FORM_STATE},
            formErrors: new Error({}),
            listUrl: '/roles',
            columns: COLUMN_DEFINATION(this),
        }
    },
    methods: {
        async handleSubmitOperation() {
            this.formErrors = new Error({})
            try {
                const response = await request({
                    url: this.formFields.id ? 'roles/update' : 'roles/create',
                    method: 'post',
                    data: {
                        ...this.formFields,
                        permissions: this.formFields.permissions.filter((item) => item.checked).map((item) => {
                            return item.id
                        })
                    }
                })

                if (this.formFields.id) {
                    this.$global.itemUpdated()
                } else {
                    this.$global.itemAdded()
                }

                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }
                this.$global.handleServerError(error)
            }
        },
        async handleDeleteOperation(id) {
            if (confirm('are you sure?')) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/roles/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.$global.itemDeleted()
                } catch (errors) {
                    this.$global.handleServerError(errors)
                }
            }
        },
        async handleEditOperation(id) {
            this.getPermissions()

            try {
                const response = await request({
                    method: "get",
                    url: `/roles/detail/${id}`,
                })
                this.operationTitle = 'Edit Role'
                const {data} = response.data

                const matchedIndexes = data.permissions
                    .map((item) => this.formFields.permissions.findIndex((subkey) => subkey.id === item.id))

                matchedIndexes.map((item, index) => {
                    this.formFields.permissions[item].checked = true;
                })

                this.formFields = {
                    ...data,
                    permissions: this.formFields.permissions
                }
            } catch (e) {
                this.$global.itemEditFails()
                this.formFields = {...DEFAULT_FORM_STATE}
            }
        },
        async getPermissions() {
            try {
                const response = await request({
                    url: '/dropdowns/permissions',
                    method: "post"
                })

                const {data} = response.data
                this.formFields.permissions = data.map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        checked: false
                    }
                })
                this.permissions = this.formFields.permissions;
            } catch (e) {

            }
        },
        resetPermissions() {
            this.formFields.permissions = this.permissions.map((item) => {
                return {...item, checked: false}
            })
        },
        handleOperationClose() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$router.push({name: "roleList"})
        },
    },
}
